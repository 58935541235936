import { intersection } from 'lodash';
import clone from 'lodash/clone';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import last from 'lodash/last';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import { isNumber, removeAccent } from '../../../utils/functions';
import {
  CAR_NUMBER,
  MOTORCYCLE_NUMBER,
  BUS_NUMBER,
  CARGO_VEHICLE_NUMBER,
  NAUTICAL_VEHICLE_NUMBER,
  AGRICULTURAL_VEHICLE_NUMBER,
  SERVICES_NUMBER,
  PARTS_NUMBER,
  STORES_NUMBER,
} from '../../../utils/productType';
import { Values } from './Type';
import { Photo } from './Type';

export const photosGallery = (photoUrl: any) => {
  let newPhotoUrl = [
    {
      id: 1,
      url: '',
      principal: true,
      type: 1,
      base64: '',
    },
  ];

  if (photoUrl) {
    newPhotoUrl = photoUrl.map((item: any, index: number) => {
      const newItem = {
        id: index + 1,
        url: `${process.env.FTP_IMAGENS}${item}`,
        principal: index === 0,
        type: 2,
        base64: '',
      };
      return newItem;
    });
  }

  if (newPhotoUrl.length < 26) {
    for (let i = newPhotoUrl.length; i < 25; i++) {
      newPhotoUrl.push({
        id: i + 1,
        url: '',
        principal: i === 0,
        type: 1,
        base64: '',
      });
    }
  }

  return newPhotoUrl;
};

export const organizedPhotos = (photos: Photo[]) => {
  const newArray = [];
  let count = 0;

  photos = orderBy(photos, ['principal'], ['desc']);

  forEach(photos, (item: any) => {
    if (item.url || item.base64) {
      item.id = count + 1;
      item.principal = count === 0;
      count = count + 1;
      newArray.push(item);
    }
  });

  if (newArray.length < 26) {
    for (let i = newArray.length; i <= 25; i++) {
      newArray.push({
        id: i + 1,
        url: '',
        principal: i === 0,
        type: 1,
        base64: '',
      });
    }
  }

  return newArray;
};

export const formatAdParts = (data: any) => {
  const { photoUrl } = data;
  const newPhotoUrl = photosGallery(photoUrl);
  const { name, description, price, newPart } = data;
  const limitCount = get(data, 'limitCount', '');
  const partType = get(data, 'partType', '');
  const modelType = get(data, 'modelType', 1);
  const warranty = get(data, 'warranty', '');
  const models = get(data, 'models', []).map(({ id, model }: { id: number; model: string }) => ({
    value: id,
    label: model,
  }));

  const formatedDate = formatDate(data.create_date);

  return {
    title: `${name}`,
    date: formatedDate,
    status: get(data, 'status', 0),
    views: get(data, 'views', 0),
    id: get(data, 'id', 0),
    Parts: {
      name,
      description,
      price,
      newPart,
      warranty,
      limitCount,
      partType,
      modelType,
      photos: newPhotoUrl,
      models,
    },
  };
};

const formatModel = ({ model, id }: { model: string; id: number }) => ({
  value: id,
  label: model,
});

export const formatAd = (data: any) => {
  const { photoUrl } = data;
  const newPhotoUrl = photosGallery(photoUrl);
  const nfMotor = get(data, 'model.motor', 0);
  const motor = nfMotor ? nfMotor.toFixed(1).toString() : get(data, 'motor', '');
  const name = get(data, 'model.name', '');
  const brandName = get(data, 'model.brandName', '');
  const formatedDate = formatDate(data.create_date || data.date);

  return {
    title: `${brandName} ${name}`,
    date: formatedDate,
    status: get(data, 'status', 0),
    views: get(data, 'views', 0),
    proposals: get(data, 'proposals', 0),
    id: get(data, 'id', 0),
    Car: {
      id: get(data, 'id', 0),
      modelId: get(data, 'model.id', 0),
      modelYear: get(data, 'model.modelYear', 0),
      vehicleType: get(data, 'model.type', 1),
      fabricationYear: get(data, 'model.fabricationYear', 0),
      name,
      fuelType: get(data, 'fuelType', ''),
      city: get(data, 'city', ''),
      plate: get(data, 'plate', ''),
      motor,
      color: get(data, 'color', ''),
      state: get(data, 'state', ''),
      version: get(data, 'model.version', ''),
      bodyStyle: get(data, 'bodyStyle', ''),
      brandName,
      transmission: get(data, 'transmission', ''),
      ports: get(data, 'ports', ''),
      coolingType: get(data, 'coolingType', ''),
      marches: get(data, 'marches', ''),
      matchType: get(data, 'matchType', ''),
      loadCapacity: get(data, 'loadCapacity', ''),
      axles: get(data, 'axles', ''),
      cargoVehicleType: get(data, 'cargoVehicleType', ''),
      busType: get(data, 'busType', ''),
      seatsAmount: get(data, 'seatsAmount', ''),
      length: get(data, 'length', ''),
      capacityOfPerson: get(data, 'capacityOfPerson', ''),
    },
    Optionals: get(data, 'optionals', []),
    Ad: {
      price: get(data, 'price', 0),
      kilometers: get(data, 'mileage', null) || null,
      description: get(data, 'description', ''),
      isInAutoscarPlay: get(data, 'isInAutoscarPlay', false) || false,
    },
    Gallery: {
      photos: newPhotoUrl,
      photos64: [],
    },
    Parts: {
      name: get(data, 'name', ''),
      description: get(data, 'description', ''),
      price: get(data, 'price', 0),
      newPart: get(data, 'newPart', false),
      warranty: get(data, 'warranty', ''),
      limitCount: get(data, 'limitCount', ''),
      partType: get(data, 'partType', ''),
      modelType: get(data, 'modelType', ''),
      photos: newPhotoUrl,
      type: get(data, 'type', ''),
      status: get(data, 'status', 0),
      models: get(data, 'models', []).map(formatModel),
      modelsId: get(data, 'models', []),
      partCategorie: get(data, 'partCategorie', null),
    },
  };
};

const formatDate = (date: string | null) => {
  if (!date) {
    return '00/00/0000';
  }
  const arrayDate = date.substring(0, 10).split('-');
  return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
};

export const formatProposals = (proposals: any, carName: string) => {
  return map(proposals, ({ createDate, description, ...rest }) => {
    const date = formatDate(createDate);
    return {
      date,
      proposal: description,
      carName,
      ...rest,
    };
  });
};

export const vehicleTypeNumberByName = (name: string | number | string[] | null = 'carros') => {
  let newName = clone(name);
  if (typeof newName === 'string') {
    newName = newName.toLowerCase();
  }
  switch (newName) {
    case 'carros':
      return CAR_NUMBER;
    case 'motos':
      return MOTORCYCLE_NUMBER;
    case 'onibus':
      return BUS_NUMBER;
    case 'carga':
      return CARGO_VEHICLE_NUMBER;
    case 'nautica':
      return NAUTICAL_VEHICLE_NUMBER;
    case 'agricola':
      return AGRICULTURAL_VEHICLE_NUMBER;
    case 'servicos':
      return SERVICES_NUMBER;
    case 'pecas':
      return PARTS_NUMBER;
    default:
      return 1;
  }
};

export const vehicleTypeNameByNumber = (num: number | string) => {
  switch (Number(num)) {
    case CAR_NUMBER:
      return 'carros';
    case MOTORCYCLE_NUMBER:
      return 'motos';
    case BUS_NUMBER:
      return 'onibus';
    case CARGO_VEHICLE_NUMBER:
      return 'carga';
    case NAUTICAL_VEHICLE_NUMBER:
      return 'nautica';
    case AGRICULTURAL_VEHICLE_NUMBER:
      return 'agricola';
    case SERVICES_NUMBER:
      return 'servicos';
    case PARTS_NUMBER:
      return 'pecas';
    default:
      return 'carros';
  }
};

export const vehicleTypeNameByNumberStepParts = (num: number | string) => {
  switch (Number(num)) {
    case CAR_NUMBER:
      return 'Carros';
    case MOTORCYCLE_NUMBER:
      return 'Motos';
    case BUS_NUMBER:
      return 'Ônibus';
    case CARGO_VEHICLE_NUMBER:
      return 'Carga';
    case NAUTICAL_VEHICLE_NUMBER:
      return 'Nautica';
    case AGRICULTURAL_VEHICLE_NUMBER:
      return 'Agrícola';
    case SERVICES_NUMBER:
      return 'Servicos';
    case PARTS_NUMBER:
      return 'Pecas';
    default:
      return 'Carros';
  }
};

export const vehicleTypeNameByNumberStepOneHome = (num: number | string) => {
  switch (Number(num)) {
    case CAR_NUMBER:
      return 'Carros';
    case MOTORCYCLE_NUMBER:
      return 'motos';
    case BUS_NUMBER:
      return 'onibus';
    case CARGO_VEHICLE_NUMBER:
      return 'carga';
    case NAUTICAL_VEHICLE_NUMBER:
      return 'nautica';
    case AGRICULTURAL_VEHICLE_NUMBER:
      return 'agricola';
    case SERVICES_NUMBER:
      return 'servicos';
    case PARTS_NUMBER:
      return 'pecas';
    case STORES_NUMBER:
      return 'lojas';
    default:
      return 'carros';
  }
};

export const treatPath = (path: string) => {
  const querys = path.split('/');
  const types = ['carros', 'motos', 'carga', 'agricola', 'onibus', 'nautica', 'pecas'];
  const decodedQuery = map(querys, query => removeAccent(decodeURI(query)).toLowerCase());
  const [queryType] = intersection(types, decodedQuery);
  const type = queryType ? vehicleTypeNumberByName(queryType) : null;
  return {
    id: isNumber(last(decodedQuery)) ? last(decodedQuery) : '',
    type,
  };
};

export const stepKeys: (keyof Values)[] = ['Car', 'Optionals', 'Ad', 'Gallery', 'Parts'];

export const INACTIVE = 0;
export const ACTIVE = 1;
export const DRAFT = 2;
export const EXCLUDED = 3;
